import * as React from "react";
import Layout from "../../components/Layout";
import { graphql, PageProps } from "gatsby";
import SEO from "../../components/Seo";
import HeroImage from "../../components/HeroImage";
import { ImageInterface, LocalizedContextInterface, PortableText } from "../../types/SanityTypes";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import RichText from "../../components/RichText";
import "./AdvancedReferenceTable.scss";
import { TableInterface } from "../../components/TableBlock";
import { LocalisedPageContext } from "../../types/PageTypes";

type SureAdvancedReferenceProps = AdvancedReferenceInterface & TableInterface & LocalizedContextInterface;

interface AdvancedReferenceInterface {
  sanityAdvancedReference: {
    _rawTextBody: PortableText[];
    banner: {
      _rawHeroImage: ImageInterface;
      headline: {
        primaryText: string;
      };
      primaryColor: {
        value: string;
      };
    };
  };
}

type AdvancedReferencePageContext = LocalisedPageContext;

export const query = graphql`
  query AdvancedReference($_id: String, $language: String, $market: String) {
    sanityAdvancedReference(_id: { eq: $_id }) {
      banner {
        _rawHeroImage
        headline {
          primaryText
        }
        primaryColor {
          value
        }
      }
      _rawTextBody(resolveReferences: { maxDepth: 10 })
    }
    ...LocalizedContext
  }
`;

const SureAdvancedReference = (props: PageProps<SureAdvancedReferenceProps, AdvancedReferencePageContext>) => {
  const pageTitle = props.data.sanityAdvancedReference.banner.headline.primaryText;
  const rawHeroImage = props.data.sanityAdvancedReference.banner._rawHeroImage;
  const sureAdvancedReferenceTheme = props.data.sanityAdvancedReference.banner.primaryColor.value;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"sureAdvancedReference"}
    >
      <SEO title={pageTitle} description={pageTitle} imageUrl={rawHeroImage?.asset.url} imageAlt={rawHeroImage?.alt} />
      {rawHeroImage && <HeroImage image={rawHeroImage} />}
      <div className="page_sure-advanced-reference">
        <Container fluid>
          <h1 style={{ color: sureAdvancedReferenceTheme }}>{pageTitle}</h1>
          <RichText data={props.data.sanityAdvancedReference._rawTextBody} />
        </Container>
      </div>
    </Layout>
  );
};

export default SureAdvancedReference;
